import React from 'react';

export default {
  sm: (
    <svg width="16" height="16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.26 8c.327 0 .592.224.592.5V13h8.296V8.5c0-.276.266-.5.593-.5.327 0 .592.224.592.5v5c0 .276-.265.5-.592.5H3.259c-.327 0-.592-.224-.592-.5v-5c0-.276.265-.5.592-.5Z"
        className="icon-color"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2 5.81c0-.263.244-.477.545-.477h10.91c.3 0 .545.214.545.477v2.38c0 .263-.244.477-.546.477H2.545c-.3 0-.545-.214-.545-.477V5.81Zm1.09.476v1.428h9.82V6.286H3.09Z"
        className="icon-color"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 5.333c.368 0 .667.229.667.51v7.647c0 .282-.299.51-.667.51-.368 0-.667-.228-.667-.51V5.843c0-.281.299-.51.667-.51Z"
        className="icon-color"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.407 3.143a.906.906 0 0 0-.628.25.842.842 0 0 0-.26.607c0 .227.093.445.26.606.167.16.393.251.628.251h1.87a4.61 4.61 0 0 0-.278-.582c-.372-.653-.884-1.132-1.592-1.132Zm2.667 2.286.581-.113V5.31l-.003-.009a2.503 2.503 0 0 0-.033-.135 5.993 5.993 0 0 0-.582-1.443C7.597 2.95 6.775 2 5.408 2c-.55 0-1.077.21-1.466.586A1.964 1.964 0 0 0 3.333 4c0 .53.219 1.04.608 1.414.389.375.916.586 1.466.586h2.667a.601.601 0 0 0 .458-.21.556.556 0 0 0 .123-.473l-.58.112Z"
        className="icon-color"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.962 3.725C8.404 2.95 9.226 2 10.592 2c.55 0 1.078.21 1.467.586.39.375.608.884.608 1.414 0 .53-.219 1.04-.608 1.414A2.114 2.114 0 0 1 10.593 6H7.926a.601.601 0 0 1-.458-.21.556.556 0 0 1-.123-.473l.58.112-.58-.113V5.31l.003-.009a2.503 2.503 0 0 1 .033-.135 5.993 5.993 0 0 1 .582-1.443Zm.762 1.132h1.869c.235 0 .461-.09.628-.25a.842.842 0 0 0 .26-.607.842.842 0 0 0-.26-.606.906.906 0 0 0-.628-.251c-.708 0-1.22.479-1.592 1.132a4.61 4.61 0 0 0-.277.582Zm-.217.685Z"
        className="icon-color"
      />
    </svg>
  ),
  md: (
    <svg width="24" height="24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.889 12c.49 0 .889.336.889.75v6.75h12.444v-6.75c0-.414.398-.75.89-.75.49 0 .888.336.888.75v7.5c0 .414-.398.75-.889.75H4.89C4.398 21 4 20.664 4 20.25v-7.5c0-.414.398-.75.889-.75Z"
        className="icon-color"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3 8.714C3 8.32 3.366 8 3.818 8h16.364c.452 0 .818.32.818.714v3.572c0 .394-.366.714-.818.714H3.818C3.366 13 3 12.68 3 12.286V8.714Zm1.636.715v2.142h14.728V9.43H4.636Z"
        className="icon-color"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 8c.552 0 1 .342 1 .765v11.47c0 .423-.448.765-1 .765s-1-.342-1-.765V8.765c0-.423.448-.765 1-.765Z"
        className="icon-color"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.111 4.714c-.354 0-.693.136-.943.377s-.39.568-.39.909c0 .341.14.668.39.91.25.24.59.376.943.376h2.804a6.91 6.91 0 0 0-.416-.873c-.56-.98-1.327-1.699-2.388-1.699Zm4 3.429.872-.169v-.002l-.002-.005-.003-.014a8.887 8.887 0 0 0-.207-.739 8.985 8.985 0 0 0-.714-1.627C11.393 4.424 10.16 3 8.11 3a3.17 3.17 0 0 0-2.2.879A2.947 2.947 0 0 0 5 6c0 .796.328 1.559.911 2.121a3.17 3.17 0 0 0 2.2.879h4a.902.902 0 0 0 .688-.314.834.834 0 0 0 .184-.711l-.872.168Z"
        className="icon-color"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.943 5.587C12.607 4.424 13.84 3 15.89 3a3.17 3.17 0 0 1 2.2.879C18.672 4.44 19 5.204 19 6s-.328 1.559-.911 2.121a3.17 3.17 0 0 1-2.2.879h-4a.902.902 0 0 1-.688-.314.834.834 0 0 1-.184-.711l.872.168-.872-.169v-.002l.002-.005.003-.014.01-.045.039-.157a8.985 8.985 0 0 1 .873-2.164Zm1.142 1.699h2.804c.354 0 .693-.136.943-.377s.39-.568.39-.909c0-.341-.14-.668-.39-.91a1.359 1.359 0 0 0-.943-.376c-1.061 0-1.829.719-2.388 1.7a6.91 6.91 0 0 0-.416.872Zm-.325 1.026Z"
        className="icon-color"
      />
    </svg>
  ),
};
